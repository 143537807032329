'use client';

import {
    MouseEvent,
    useCallback,
    useMemo,
    useRef,
    useState,
} from 'react';
import { buildClassNames } from '@frontend/jetlend-web-ui/src/utils/classNameUtils';
import Image, { StaticImageData } from 'next/image';
import styles from './StatisticBlock.module.scss';
import SlidesShow from './SlidesShow/SlidesShow';
import SlidesShowBackgroundItem from './SlidesShow/SlidesShowBackgroundItem';
import InvestorAppImage from './images/investor-app.png';
import PartnerAppImage from './images/partner-app.png';
import BorrowerAppImage from './images/borrower-app.png';
import ShowMore from '@app/ui/ShowMore/ShowMore';
import { IStatisticsBlockApiModel } from '@app/models/common/common';

export type StatisticBlockType = IStatisticsBlockApiModel & { imageSrc?: string | StaticImageData; linkHref?: string };

export interface IProps {
    /**
     * Блоки статистики
     */
    statisticBlocks: IStatisticsBlockApiModel[];
}

/**
 * Компонент блока статистики на баннере главной страницы
 */
export default function StatisticBlock({
    statisticBlocks,
}: IProps) {
    const statisticBlocksRef = useRef<HTMLDivElement[]>([]);

    const [currentSlide, setCurrentSlide] = useState<number>(0);
    const didSlideChange = useCallback((index: number) => {
        setCurrentSlide(index);
    }, []);

    const didMouseOver = useCallback((event: MouseEvent<HTMLDivElement>) => {
        statisticBlocksRef.current.forEach(block => {
            block.classList.toggle(styles['statistic-block--active'], block === event.currentTarget);
        });
    }, []);

    const statisticBlocksWithImages = useMemo<StatisticBlockType[]>(() => statisticBlocks?.map(item => {
        // Делаем из абсолютного URL относительный
        const pathName = function() {
            const url = new URL(item.value_url);
            return url ? url.pathname + url.search + url.hash : null;
        }();

        switch (item.title) {
        case 'Инвесторам':
            return {
                ...item,
                imageSrc: InvestorAppImage,
                linkHref: pathName || '/investor',
            };
        case 'Партнерам':
            return {
                ...item,
                imageSrc: PartnerAppImage,
                linkHref: pathName || '/partneram',
            };
        case 'Предпринимателям':
            return {
                ...item,
                imageSrc: BorrowerAppImage,
                linkHref: pathName || '/borrower',
            };
        default:
            return item;
        }
    }), [statisticBlocks]);

    const slidesContent = useMemo(() => (
        statisticBlocksWithImages.map((block, index) => (
            <SlidesShowBackgroundItem
                key={index}
                title={block.title}
                description={block.description}
                value={block.value}
                verticalAlignment="start"
                contentImage={block.imageSrc}
                footer={<ShowMore linkHref={block.linkHref} />}
            />
        ))
    ), [statisticBlocksWithImages]);

    return (
        <div className={styles['statistic']}>
            <div className={styles['desktop-mode']}>
                {statisticBlocksWithImages.map((block, index) => (
                    <div
                        key={index}
                        className={buildClassNames(styles, ['statistic-block', index === 0 && 'statistic-block--active'])}
                        ref={block => {
                            statisticBlocksRef.current[index] = block;
                        }}
                        onMouseOver={didMouseOver}
                        // onMouseLeave={didMouseBlockLeave}
                    >
                        <h3 className={styles['statistic-title']}>{block.title}</h3>
                        <span className={styles['statistic-description']}>{block.description}</span>
                        <h3 className={styles['statistic-value']}>{block.value}</h3>
                        <div className={styles['statistic-more']}>
                            <ShowMore linkHref={block.linkHref} />
                        </div>
                        <div className={styles['statistic-background']}>
                            <div className={styles['image-wrapper']}>
                                <Image src={block.imageSrc} alt="" fill />
                            </div>
                        </div>
                    </div>
                ))}
            </div>
            <div className={styles['mobile-mode']}>
                <SlidesShow activeSlide={currentSlide} onSlideChange={didSlideChange}>
                    {slidesContent}
                </SlidesShow>
            </div>
        </div>
    );
};


